@import '../../../scss/theme-bootstrap';

.offer-promo-v1 {
  text-align: #{$ldirection};
  display: table;
  height: 60px;
  cursor: pointer;
}

.offer-promo-v1 .content {
  height: 100%;
  *position: absolute;
  top: 50%;
  display: table-cell;
  vertical-align: bottom;
  width: auto;
}

.offer-promo-v1 .headline,
.offer-promo-v1 .cta {
  white-space: nowrap;
}

.offer-promo-v1 .headline {
  font-size: 15px;
  font-family: $base-font-family;
  font-family: $base-bold-font-family;
  -webkit-font-smoothing: antialiased;
  color: $color-cl-green-2;
  text-transform: uppercase;
  margin-#{$rdirection}: 8px;
}

.offer-promo-v1 .cta,
.offer-promo-v1 .cta a {
  color: #000;
}

.offer-promo-v1 .cta span,
.offer-promo-v1 .cta span a {
  color: inherit;
}

@media screen and (max-width: 640px) {
  #header .top-right .block.block-template-offer-promo-v1 {
    display: none;
  }
}
